@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #292929;
}

h1, h2, h3, h4 {
  color: rgb(244,191,64) !important;
}

[data-slate-editor] {
  -webkit-user-modify: read-write !important;
}